<template>
    <div>
        <textarea v-model="input" v-bind="attributes" :rows="7" :class="classes" :placeholder="placeholder" @input="handleInput" />

        <template v-for="(errorMessage, index) in errors[name]">
            <v-text :key="'error_message_' + index" type="error">
                {{ errorMessage }}
            </v-text>
        </template>
    </div>
</template>

<script>
import FormMixin from "./form-mixin";

export default {
    mixins: [
        FormMixin,
    ],
    props: {
        placeholder: {
            type: String,
            required: false,
            default: () => null,
        },
    },
    data() {
        return {
            /**
             * The base classes for the component.
             *
             * @var String
             */
            baseClasses: "border-none focus:ring-inset focus:ring-2 focus:ring-primary-900 mt-1 block w-full rounded-none disabled:opacity-75 disabled:cursor-not-allowed",

            /**
             * The available component states.
             *
             * @var Object
             */
            states: {
                /**
                 * The classes for when the component is in a default state.
                 */
                default: "bg-gray-100",

                /**
                 * The classes for when the component is readonly.
                 */
                readonly: "bg-transparent",

                /**
                 * The classes for when the component has errors.
                 */
                error: "bg-gray-100 border-red-500 focus:border-red-500 ring-red-500 focus:ring-red-500",
            },
        };
    },
    computed: {
        classes() {
            return `form-select ${this.baseClasses} ${this.states[this.state]}`;
        },
    },
};
</script>
